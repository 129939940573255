import cardBakaiVisa from "../res/card_bakai_visa.png";
//import cardFreedomFinance from "../res/card_freedom_finance.png";
import cardF from "../res/card_f.png"
import cardBccPay from "../res/card_bcc_bcc_pay.png";
import cardBsb from "../res/card_bsb.png";
import cardJysan from "../res/card_jysan_mc_debit.png";
import cardPyypl from "../res/card_pyypl.png";

function MainCardsContent(payload) {
    return (
        <div className="basic_container_wrapper basic_container_wrapper_double second_background">
            <div className="full_height_container_text basic_container_vertical" ref={payload.reference}>
                <div>
                    <h3 className="margin_h3 header2">Список актуальных вариантов зарубежных карт</h3>
                </div>
                <div className="basic_container_centered">
                    <div className="equal_images_container_card">
                        <div className="first_item cards_info">
                            <h6>Банк Бакай, Киргизия</h6>
                            <img className="equal_images_cards" src={cardBakaiVisa} alt="card_image_01"/>
                            <p>Visa Classic</p>
                            <ul>
                                <li>
                                    <p>
                                        Без предоплаты, оплата по факту выпуска карты и активации мобильного банка
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        По доверенности
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Карта + мультивалютный счет
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Карта именная эмбоссированная
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="cards_info">
                            <h6>Forte Bank, Казахстан</h6>
                            <img className="equal_images_cards" src={cardF} alt="card_image_02"/>
                            <p>Мультвалютная Deposit Card, MasterCard</p>
                            <ul>
                                <li>
                                    <p>
                                        Четыре валютных счёта с полноценным IBAN (USD, EUR, KZT, RUB)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Бесплатное обслуживание
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Доступен входящий/исходящий SWIFT
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Без доверенности
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="cards_info">
                            <h6>БСБ Банк, Беларусь</h6>
                            <img className="equal_images_cards" src={cardBsb} alt="card_image_03"/>
                            <p>Visa/Mastercard USD + Белкарт</p>
                            <ul>
                                <li>
                                    <p>
                                        Оформление по внутреннему паспорту РФ
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        По доверенности
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Без предоплаты
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Готовность карты 1 день
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Возможность выпустить виртуальную карту
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="first_item cards_info">
                            <h6>Банк Jusan, Казахстан</h6>
                            <img className="equal_images_cards" src={cardJysan} alt="card_image_04"/>
                            <p>Mastercard Debit</p>
                            <ul>
                                <li>
                                    <p>
                                        Без предоплаты, оплата по факту выпуска карты
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Мультилюватная именная карта в: $,€,Т,₽
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Первый год обслуживания бесплатно
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        По доверенности
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="cards_info">
                            <h6>Карта PYYPL, Финляндия</h6>
                            <img className="equal_images_cards" src={cardPyypl} alt="card_image_05"/>
                            <p>Mastercard</p>
                            <ul>
                                <li>
                                    <p>
                                        Бесплатная виртуальная или физическая
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Без предоплаты, оплата после получения карты
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Входящие и исходящие SWIFT переводы в 40 стран
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Бесплатное обслуживание
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Без доверенности
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="cards_info">
                            <h6>Банк ВСС ЦентрКредит, Казахстан</h6>
                            <img className="equal_images_cards" src={cardBccPay} alt="card_image_06"/>
                            <p>BCC PAY</p>
                            <ul>
                                <li>
                                    <p>
                                        Без предоплаты, оплата по факту выпуска карты
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Бесплатное обслуживание
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Конвертация валюты в мобильном приложение банка по курсу Forex
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        По доверенности
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MainCardsContent